<template>
  <div class="screen-recorder">
    <iframe
        src="https://screen-recorder.testenv.ro"
        allow="camera; microphone; display-capture; fullscreen"
        sandbox="allow-scripts allow-same-origin allow-popups allow-forms allow-modals allow-downloads"
        width="100%"
        height="100%"
    ></iframe>
  </div>
</template>

<script setup>

</script>

<style lang="scss">
  .screen-recorder {
    @media only screen and (min-width: 0) {
      height: 1000px;
    }
  }
</style>