import { createRouter, createWebHistory } from 'vue-router'
import NovaConsultingView from "@/views/NovaConsultingView.vue";
import ScreenRecorderView from "@/views/ScreenRecorderView.vue";
import CvTemplate1 from "@/views/CvTemplate1.vue";
import CvTemplate2 from "@/views/CvTemplate2.vue";

const routes = [
  {
    path: '/nova-consulting',
    name: 'nova',
    component: NovaConsultingView
  },
  {
    path: '/screen-recorder',
    name: 'screen',
    component: ScreenRecorderView
  },
  {
    path: '/cv-template-1',
    name: 'template-1',
    component: CvTemplate1
  },
  {
    path: '/cv-template-2',
    name: 'template-2',
    component: CvTemplate2
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
