<template>
  <main class="nova-consulting">
    <header></header>

    <div class="content">
      <section class="hero-section">
        <h1 class="text-center">
          We Resolve What Your Managers Tried to Fix
        </h1>
        <p class="text-center">
          Fast, efficient, effective: solutions that work for you
        </p>

        <div class="text-center">
          <button>
            Start ... Now / Let's Talk
          </button>
        </div>
      </section>

      <section class="about-us">
        <h2>
          What you should know about us
        </h2>
        <p>
          At Nova Consulting, we understand the challenges you face today. You're looking for results, not excuses. You need a partner who can deliver, not just make promises.
          <br><br>
          We're not here to waste your time or resources. We don't just solve problems; we create opportunities for you to grow.
          <br><br>
          Our approach is simple: we listen, we analyze, and we execute. No fluff, no jargon, just straight talk and concrete solutions. We know you're not interested in long, theoretical discussions. You want action, and that's what we deliver.
          <br><br>
          Time is money, and we respect both.
        </p>

        <div class="text-center">
          <button>
            Let's Talk
          </button>
        </div>
      </section>

      <p class="text-center">
        Precision IT solutions. Measurable business impact.
      </p>

      <section class="problem-statement">
        <h2>
          Problem Statement
        </h2>
        <p>
          common IT challenges that we solve
        </p>

        <ul>
          <li v-for="({title, description}, index) in problems"
              :key="index">
            <h3>{{ title }}</h3>
            <p>{{ description }}</p>
          </li>
        </ul>
      </section>

      <section class="our-approach m-t-24">
        <h2>
          Our Approach
        </h2>
        <p>
          We don't just consult – we transform. Our unique methodology focuses on:
        </p>

        <ul>
          <li class="m-t-24" v-for="({name}, index) in approach"
              :key="index">
            <p v-html="name" />
          </li>
        </ul>
      </section>

      <section class="how-different m-t-24">
        <h2>
          How We're Different:
        </h2>

        <ul>
          <li class="m-t-24" v-for="({title, description}, index) in different"
              :key="index">
            <h3>{{ title }}</h3>
            <p>{{ description }}</p>
          </li>
        </ul>
      </section>

      <div class="text-center">
        <button>
          Schedule Your <br>
          Free Consultation
        </button>
      </div>

    </div>
  </main>
</template>

<script setup>
  const problems = [
    {title: 'Performance Metrics:', description: 'I need better ways to measure and track performance. What KPIs should I be focusing on?'},
    {title: 'Productivity Concerns:', description: 'What can I do to boost their productivity?'},
    {title: 'Process Optimization:', description: 'Our processes seem inefficient. How can I make them just work?'},
    {title: 'Communication Issues:', description: 'There seems to be a lack of clear communication. How can I improve collaboration within the management team?'},
    {title: 'Resource Allocation:', description: 'How can we ensure that the right people are working on the right tasks?'},
    {title: 'Scalability:', description: 'As we grow, how can we ensure that our processes and team scale effectively?'},
  ];

  const approach = [
    {name: `<span>Aligning Vision:</span> <br /> We ensure everyone understands the company's goals and their role in achieving them.`},
    {name: `<span>Enhancing Communication:</span> <br /> We implement effective channels for clear, two-way dialogue.`},
    {name: `<span>Optimizing Processes:</span> <br /> through analysis we simplify and sort processes.`},
    {name: `<span>Cultivating Leadership:</span> <br /> We develop management skills that inspire and empower teams.`},
  ]

  const different = [
    {title: `Measurable Results:`, description: `We set clear KPIs and show you tangible improvements, not just theoretical advice. We measure the important things, to change the important problems.`},
    {title: `Long-term Success:`, description: `Our solutions are designed to create lasting change, not quick fixes.`},
    {title: `360° Performance Optimization`, description: `A complete, all-encompassing approach to improving performance.`},
  ]
</script>

<style lang="scss">
  .nova-consulting {
    @media only screen and (min-width: 0) {
      .content {
        padding: 12px 20px 200px;
      }
      header {
        height: 60px;
        background-color: #2c3e50;
      }
      .hero-section {
        h1 {
          font-size: 2.6rem;
          font-weight: 600;
        }
        p {
          font-size: 2rem;
          font-weight: 500;
        }
      }
      h2 {
        font-weight: 600;
        font-size: 2.2rem;
        text-align: left;
      }
      h3 {
        font-size: 2rem;
        font-weight: 600;
      }
      p {
        font-size: 1.8rem;
        line-height: 2.4rem;
      }
      section {
        margin-top: 48px;
        h2 + p {
          font-size: 1.8rem;
        }
      }
      .about-us {
        p {
          font-size: 1.8rem;
        }
      }
      .problem-statement {
        h3 {
          margin-bottom: 0;
        }
        p {
          margin-top: 8px;
          font-size: 2rem;
        }
      }
      .our-approach {
        ul p {
          span {
            font-size: 2rem;
            font-weight: 500;
          }
        }
      }
      .how-different {
        h3 {
          margin-bottom: 0;
        }
      }
    }
  }
</style>
