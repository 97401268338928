<template>
  <div class="cv-template-2">
    <div class="left-side">
      <section class="details">
        <h3>
          DETAILS
        </h3>
        <div>
          <h4>Phone</h4>
          <span>+40743 536 495</span>
        </div>
        <div>
          <h4>Email</h4>
          <span>electronrecord@gmail.com</span>
        </div>
        <div>
          <h4>Location</h4>
          <span>Romania, EU / Cluj Napoca</span>
        </div>
        <div>
          <h4>Linkedin</h4>
          <a href="https://www.linkedin.com/in/cristian-gherghel-124702a9">https://www.linkedin.com/in/cristian-gherghel-124702a9</a>
        </div>
      </section>

      <section class="tech-skills">
        <h3>SKILLS</h3>
        <ul>
          <li>HTML</li>
          <li>CSS</li>
          <li>JS (ES6)</li>
          <li>VueJS</li>
          <li>ReactJS</li>
          <li>Redux</li>
          <li>NodeJS</li>
          <li>Git</li>
          <li>Docker</li>
          <li>Nginx</li>
          <li>Linux</li>
          <li>Testing (TDD)</li>
          <li>Frontend Development</li>
          <li>Team Leadership</li>
          <li>Best Practices Implementation</li>
          <li>Mentoring</li>
        </ul>
      </section>
    </div>

    <div class="right-side">
      <header>
        <h1>Cristian Gherghel</h1>
        <h2>Software Engineer</h2>
      </header>

      <section class="summary">
        <h3>
          SUMMARY
        </h3>
        <p>
          Experienced Software Engineer with a diverse background in frontend and full-stack development. Skilled in leading engineering teams, establishing best practices, and mentoring developers. Proficient in React, Vue.js, and various modern web technologies. Currently serving as an Engineering Lead at Thin Slices and a Fractional CTO at SuperDev, with a passion for continuous learning and teaching in the field of web development.
        </p>
      </section>

      <section class="employment-history">
        <h3>
          EMPLOYMENT HISTORY
        </h3>

        <ul>
          <li>
            <div>
              <h4>Engineering Lead, Thin Slices</h4>
              <span>Romania</span>
            </div>
            <h5>
              10.2021 - present
            </h5>
            <ul>
              <li>Ensure alignment of coding best practices among over 100 developers</li>
              <li>Focus on leading, implementing best practices, and setting IT standards</li>
            </ul>
          </li>
          <li>
            <div>
              <h4>Fractional CTO, SuperDev</h4>
              <span></span>
            </div>
            <h5>
              08.2022 - present
            </h5>
            <ul>
              <li>Set best practices, standards, and vision for technologies used</li>
              <li>Focus on leading and establishing IT standards</li>
            </ul>
          </li>
          <li>
            <div>
              <h4>Frontend Lead, British Medical Journal</h4>
              <span>UK</span>
            </div>
            <h5>
              1.2020 - present
            </h5>
            <ul>
              <li>Polish and maintain React project</li>
              <li>Ensure project is in good shape and follows best practices</li>
            </ul>
          </li>
        </ul>
      </section>

      <section class="education">
        <h3>EDUCATION</h3>
        <ul>
          <li>
            <div>
              <h4>Eric Elliott JS Courses</h4>
              <span></span>
            </div>
            <h5>2020</h5>
            <ul>
              <li>list here the achievements from the education</li>
            </ul>
          </li>
          <li>
            <div>
              <h4>Team Tree House Courses</h4>
              <span></span>
            </div>
            <h5>2014 - 2016</h5>
          </li>
          <li>
            <div>
              <h4>Academy Of Music</h4>
              <span>country/city</span>
            </div>
            <h5>2010 - 2013</h5>
          </li>
        </ul>
      </section>
    </div>
  </div>
</template>

<script setup>

</script>

<style lang="scss">
  .cv-template-2 {
    @media only screen and (min-width: 0) {
      display: flex;
      h3 {
        padding-bottom: 4px;
        border-bottom: 1px solid #4a4a4a;
        font-size: 1.8rem;
        font-weight: 500;
        color: #4a4a4a;
      }
      p {
        line-height: 2.2rem;
      }
      .left-side {
        width: 34%;
        padding: 290px 40px 0;
        background-color: #F4F4F4;
      }
      .right-side {
        padding: 0 40px;
        header {
          width: 500px;
          margin-top: 60px;
          padding: 36px 0;
          border: 2px solid black;
          text-align: center;
          background-color: white;
          transform: translateX(-100px);
          h1 {
            text-transform: uppercase;
            font-size: 3rem;
          }
          h2 {
            font-size: 2rem;
          }
        }

        .summary {
          margin-top: 51px;
        }
      }

      section {
        margin-bottom: 48px;
      }

      .details {
        h4 {
          padding-bottom: 0;
          margin-bottom: 0;
        }
        div {
          padding-left: 8px;
        }
      }

      .tech-skills,
      .employment-history,
      .education {
        h4 {
          margin-bottom: 3px;
          font-weight: 600;
        }
        h5 {
          margin-top: 4px;
          margin-bottom: 8px;
        }
        > ul {
          margin-left: 0;
          padding-left: 26px;
        }
      }
    }
  }
</style>